window.showErrorMessage = function (errors) {
    // Set error message text
    let message = 'There was a problem submitting your request:\n\n';
    if (errors) {
        // Iterate over each error message in the errors object
        for (let errorType in errors) {
            if (errors.hasOwnProperty(errorType)) {
                // If the error type is an array, iterate over each error message
                if (Array.isArray(errors[errorType])) {
                    // Append each error message to the message string
                    errors[errorType].forEach(errorMessage => {
                        message += `${errorMessage}\n`;
                    });
                } else {
                    // If it's not an array, just append it directly
                    message += `${errors[errorType]}\n`;
                }
            }
        }
    } else {
        // No error message arguments provided. Use the default error message.
        message = "There was a problem submitting your request.";
    }

    // Show the error message
    swal({
        title: "Oops!",
        text: message,
        type: "error",
        showCancelButton: false,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "OK",
        closeOnConfirm: true
    });
}

window.showSuccessMessage = function (message, callback) {
    swal({
        title: "Success!",
        text: message,
        type: "success",
        showCancelButton: false,
        confirmButtonClass: "btn-success",
        confirmButtonText: "OK",
        closeOnConfirm: true
    }, callback);
}

window.scroll_with_left_menu = function () {
    let wS = jQuery(window).scrollTop();
    let sT = jQuery("#free-products-section").offset().top;
    let sH = jQuery("#free-products-section").outerHeight();
    let sT1 = jQuery("#our-statistics-section").offset().top;
    let sH1 = jQuery("#our-statistics-section").outerHeight();
    let sT2 = jQuery("#your-improvement-section").offset().top;
    let sH2 = jQuery("#your-improvement-section").outerHeight();
    let sT3 = jQuery("#showcase-your-organization").offset().top;
    let sH3 = jQuery("#showcase-your-organization").outerHeight();
    let sT4 = jQuery("#maximize-your-resources").offset().top;
    let sH4 = jQuery("#maximize-your-resources").outerHeight();

    let transform = "0px";
    let active_menu = "#free-products-section";
    let next_menu = "#our-statistics-section";
    let iconac = '';
    if (wS >= sT && wS < (sT + sH)) {
        transform = "0px";
        next_menu = "#our-statistics-section";
        active_menu = "#free-products-section";
    } else if (wS >= sT1 && wS < (sT1 + sH1)) {
        transform = "0px";
        next_menu = "#your-improvement-section";
        active_menu = "#our-statistics-section";
    } else if (wS >= sT2 && wS < (sT2 + sH2)) {
        transform = "-30px";
        next_menu = "#showcase-your-organization";
        active_menu = "#your-improvement-section";
    } else if (wS >= sT3 && wS < (sT3 + sH3)) {
        transform = "-55px";
        next_menu = "#maximize-your-resources";
        active_menu = "#showcase-your-organization";
    } else if (wS >= sT4 && wS < (sT4 + sH4)) {
        transform = "-55px";
        next_menu = "#free-products-section";
        active_menu = "#maximize-your-resources";
        iconac = 'arrow-up';
    }

    $('.scroll-nav-fixed ul.left-menu').css({
        "transform": "translateY(" + transform + ")",
        "transition": "transform 0.5s"
    });
    $('.scroll-nav-fixed ul.left-menu li').removeClass('active');
    $('.scroll-nav-fixed ul.left-menu li a[href="' + active_menu + '"]').parent('li').addClass('active');
    $('.scroll-nav-fixed ul.arrow-icon a').removeClass('arrow-up').addClass(iconac).attr('href', next_menu);
}

$(document).ready(function () {
    $(document).on('click', '#frmOrgRegister', function (event) {
        event.preventDefault();
        event.stopPropagation();
        valid = true;
        $("#frmOrgRegister").prop("disabled", true);
        $("#frmOrgRegister").html("Creating profile...");
         // Check if terms checkbox is checked
         if(!$('input[name="orgTerms"]').is(':checked')){
             $('#terms-error').show();
             valid = false;
             $("#frmOrgRegister").prop("disabled", false);
             $("#frmOrgRegister").html("Claim My Profile");
         }
         
         // If form is valid, submit it
        if(valid){
            $('#terms-error').hide();

            $.ajax({
                url: '/org/register-claim',
                data: $('#claim-your-profile-form').serializeArray(),
                method: 'POST',
            }).done(function (response) {
                if (response.success) {
                    location.reload();
                } else {
                    showErrorMessage(response.errors);
                    $("#frmOrgRegister").prop("disabled", false);
                    $("#frmOrgRegister").html("Claim My Profile");
                }
            }).fail(function (jqXHR, textStatus, errorThrown) {
                $("#frmOrgRegister").prop("disabled", false);
                $("#frmOrgRegister").html("Claim My Profile");
                // Parse the error response if it's JSON, otherwise, show a generic error message
                var errors = [];
                try {
                    errors = JSON.parse(jqXHR.responseText).errors;
                } catch (e) {
                    errors.push('There was a problem with the request. Please try again.');
                }
                showErrorMessage(errors);
            });
            
        }
    });


    let $window = jQuery(window);
    let $sidebar = jQuery("#blue-background-sidebar");
    let $sidebarHeight = $sidebar.innerHeight();

    $window.scroll(function () {
        let $footerOffsetTop = jQuery(".normal-footer").offset().top;
        let $sidebarOffset = $sidebar.offset();
        if ($window.scrollTop() > $sidebarOffset.top) {
            $sidebar.addClass("fixed");
        } else {
            $sidebar.removeClass("fixed");
        }
        if ($window.scrollTop() + $sidebarHeight > $footerOffsetTop) {
            $sidebar.css({"top": -($window.scrollTop() + $sidebarHeight - $footerOffsetTop)});
        } else {
            $sidebar.css({"top": "0",});
        }
        scroll_with_left_menu();
    });
    scroll_with_left_menu();

    $('ul.tabs-improvment li').click(function () {
        let tab_id = $(this).attr('data-tab');

        $(this).parents('.tab-container').find('ul.tabs-improvment li').removeClass('current');
        $(this).parents('.tab-container').find('.tabs-improvment-content').removeClass('current');

        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
    });

    $(document).on('click', '.scroll-nav-fixed a[href^="#"]', function (event) {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: ($($.attr(this, 'href')).offset().top + 5)
        }, 500);
    });

});
